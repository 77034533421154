
















import { Component, Prop, Vue } from "vue-property-decorator";

import { EventGoingUsers } from "@/api/structs";
import AppAvatar from "@/components/AppAvatar.vue";

@Component({ components: { AppAvatar } })
export default class EventGoingUsersSamples extends Vue {
  @Prop({ required: true }) readonly goingUsers!: EventGoingUsers;
  @Prop({ required: true }) readonly defaultFullName!: string;
}

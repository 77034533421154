import { Modal } from "@/constants";
import { EventHandler } from "@/core/event-handlers";
import router from "@/router";
import { authStore, eventsStore, modalsStore } from "@/store";

export interface FilterAppliedPayload {
  cityId: string | null;
  styleId: string | null;
}

export const FILTER_APPLIED = new EventHandler<FilterAppliedPayload>(
  "filter-applied",
  async payload => {
    await router.replace({
      query: {
        ...router.currentRoute.query,
        cityId: payload.cityId,
        styleId: payload.styleId,
      },
    });
  },
);

export interface GoingClickedPayload {
  id: string;
  going: boolean;
}

export const GOING_CLICKED = new EventHandler<GoingClickedPayload>(
  "going-clicked",
  async payload => {
    if (authStore.isAnonymous) {
      await modalsStore.open(Modal.SignIn);
      return;
    }

    await eventsStore.updateEventGoing({
      id: payload.id,
      going: payload.going,
    });
  },
);

export const ADD_EVENT_CLICKED = new EventHandler<null>(
  "add-event-clicked",
  async () => {
    if (authStore.isAnonymous) {
      await modalsStore.open(Modal.SignIn);
      return;
    }

    await router.push({ name: "event-add" });
  },
);

























import { Component, Prop, Vue } from "vue-property-decorator";

import { ApiLoading, Event } from "@/api/structs";
import { getCategoryColor } from "@/colors";
import EventListItemContent from "@/modules/event/list/components/EventListItemContent.vue";
import EventListItemImage from "@/modules/event/list/components/EventListItemImage.vue";

@Component({
  components: {
    EventListItemContent,
    EventListItemImage,
  },
})
export default class EventListItem extends Vue {
  @Prop({ required: true }) readonly event!: Event;
  @Prop({ required: true }) readonly apiLoadingEventGoing!: ApiLoading;
  @Prop({ default: "home" }) readonly backTo!: string;

  get color() {
    return getCategoryColor(this.event.category);
  }
}

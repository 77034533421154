























import { Component, Prop, Vue } from "vue-property-decorator";

import { Event } from "@/api/structs";
import { getCategoryColor } from "@/colors";
import AppIconWithText from "@/components/AppIconWithText.vue";
import AppIconWithTextList from "@/components/AppIconWithTextList.vue";
import { translateCategory } from "@/i18n/keys-dynamic";
import EventGoingUsersSamples from "@/modules/event/common/components/EventGoingUsersSamples.vue";

@Component({
  components: {
    EventGoingUsersSamples,
    AppIconWithText,
    AppIconWithTextList,
  },
})
export default class EventListItemContent extends Vue {
  @Prop({ required: true }) readonly event!: Event;

  get bullets() {
    return [
      {
        icon: ["fas", "building"],
        text: this.event.place,
      },
      {
        icon: ["fas", "user"],
        text: this.event.organizer,
      },
    ].filter(el => el.text);
  }
  get categoryColor() {
    return getCategoryColor(this.event.category);
  }
  get categoryTranslated() {
    return translateCategory(this.$t.bind(this), this.event.category);
  }
  get defaultFullName() {
    return this.$t("users.defaultFullName");
  }
}

<template>
  <ul>
    <li v-for="item in items" :key="item.text">
      <app-icon-with-text :text="item.text" :icon="item.icon" />
    </li>
  </ul>
</template>

<script>
import AppIconWithText from "@/components/AppIconWithText";
export default {
  name: "AppIconWithTextList",
  components: { AppIconWithText },
  props: {
    items: Array,
  },
};
</script>

<template>
  <span>
    <span v-if="left">
      {{ text }}&nbsp;<font-awesome-icon
        :icon="icon"
        class="has-text-centered has-icon"
    /></span>
    <span v-else>
      <font-awesome-icon :icon="icon" class="has-text-centered has-icon" />&nbsp;{{
        text
      }}</span
    >
  </span>
</template>

<script>
export default {
  name: "AppIconWithText",
  props: {
    icon: Array,
    text: String,
    left: Boolean,
  },
};
</script>

<style scoped lang="scss">
.has-icon {
  width: 1.25rem;
}
</style>

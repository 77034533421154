


























import { Component, Prop, Vue } from "vue-property-decorator";

import { ApiLoading, Event } from "@/api/structs";
import AppIconWithText from "@/components/AppIconWithText.vue";
import AppIconWithTextList from "@/components/AppIconWithTextList.vue";
import { getVerticalRange, MONTH_FORMAT_LONG, MONTH_FORMAT_SHORT } from "@/datetimes";
import EventGoingUsersSamples from "@/modules/event/common/components/EventGoingUsersSamples.vue";
import { GOING_CLICKED, GoingClickedPayload } from "@/modules/event/event-handlers";

interface Style {
  [key: string]: string;
}

@Component({
  components: {
    EventGoingUsersSamples,
    AppIconWithText,
    AppIconWithTextList,
  },
})
export default class EventListItemImage extends Vue {
  private $mq!: string;

  @Prop({ required: true }) readonly event!: Event;
  @Prop({ required: true }) readonly apiLoadingEventGoing!: ApiLoading;

  get style() {
    let style = { "--background": `url(${this.event.cover})` } as Style;

    if (this.range.bottom) {
      style["min-height"] = "220px";
    }

    return style;
  }
  get range() {
    return getVerticalRange(
      this.event.startsAt,
      this.event.endsAt,
      this.$t.bind(this),
      this.monthFormat,
    );
  }
  get monthFormat() {
    return this.$mq === "tablet" ? MONTH_FORMAT_SHORT : MONTH_FORMAT_LONG;
  }
  get goingType() {
    return this.event.going ? "is-white" : "is-cocoa-transparent";
  }
  get goingIcon() {
    return this.event.going ? "check" : "plus";
  }
  get goingClass() {
    return this.event.going ? "" : "has-border";
  }

  emitGoingClicked() {
    this.$emit(GOING_CLICKED.name, {
      id: this.event.id,
      going: !this.event.going,
    } as GoingClickedPayload);
  }
}
